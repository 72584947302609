import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import Beach from "../components/beach"
import Seo from "../components/seo"
import Pagenation from "../components/pagination"

const options = {
  renderText: text => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

const IndexPage = ({ data, location, pageContext }) => (
  <div className="body">
    <Seo pagepath={location.pathname} />
    <Beach />
    <Layout>
      <main className="philosophy">
        <h2 className="philosophy__title">ぽんこつフィロソフィ</h2>
        {data.allContentfulPhilosophy.edges.map(({ node }) => (
          <article className="philosophy__post" key={node.id}>
            <p className="philosophy__picture">
              <p className="philosophy__cassette">
                <Link to={`/philosophy/${node.slug}/`} className="philosophy__pictureLink"><GatsbyImage image={node.eyecatch.gatsbyImageData} alt={node.eyecatch.description} className="philosophy__image" /></Link>
              </p>
              <p className="philosophy__wheelL">
                <Link to={`/philosophy/${node.slug}/`} className="philosophy__pictureLink"><StaticImage src="../images/philosophy_wheelL.png" alt="" quality={50} layout="constrained" placeholder="dominantColor" className="philosophy__image" /></Link>
              </p>
              <p className="philosophy__wheelR">
                <Link to={`/philosophy/${node.slug}/`} className="philosophy__pictureLink"><StaticImage src="../images/philosophy_wheelR.png" alt="" quality={50} layout="constrained" placeholder="dominantColor" className="philosophy__image" /></Link>
              </p>
            </p>
            <div className="philosophy__postTitle"><Link to={`/philosophy/${node.slug}/`} className="philosophy__postTitleLink">{renderRichText(node.title, options)}</Link></div>
            <p className="philosophy__postDate"><time dateTime={node.publishDate}>{node.publishDateJP}</time></p>
          </article>
        ))}
        <div className="philosophy__pagination">
          <Pagenation pageContext={pageContext} />
        </div>
      </main>
    </Layout>
  </div>
)

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
  allContentfulPhilosophy(
    sort: {order: DESC, fields: publishDate}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        title {
          raw
        }
        slug
        id
        eyecatch {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          description
        }
        publishDateJP:publishDate(formatString: "YYYY年MM月DD日")
        publishDate
      }
    }
  }
}
`

export default IndexPage