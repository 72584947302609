import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Parallax from "react-rellax"

const Beach = () => (
  <div className="beach">
    <p className="beach__background"><StaticImage src="../images/beach_background.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
    <p className="beach__cloud"><Parallax speed={-4}><StaticImage src="../images/beach_cloud.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></Parallax></p>
    <p className="beach__sun"><Parallax speed={-3}><StaticImage src="../images/beach_sun.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></Parallax></p>
    <p className="beach__sea"><Parallax speed={-0.5}><StaticImage src="../images/beach_sea.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></Parallax></p>
    <p className="beach__city3"><Parallax speed={-2}><StaticImage src="../images/beach_city3.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></Parallax></p>
    <p className="beach__city2"><Parallax speed={-1}><StaticImage src="../images/beach_city2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></Parallax></p>
    <p className="beach__city1"><StaticImage src="../images/beach_city1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
    <p className="beach__beach"><StaticImage src="../images/beach_beach.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
    <p className="beach__foreground"><StaticImage src="../images/beach_foreground.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
    <p className="beach__bear"><StaticImage src="../images/beach_bear.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
    <p className="beach__pigeon"><Parallax speed={-3}><StaticImage src="../images/beach_pigeon.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></Parallax></p>
    <p className="beach__wing"><StaticImage src="../images/beach_wing.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
    <p className="beach__palm"><StaticImage src="../images/beach_palm.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="beach__image" /></p>
  </div>
)

export default Beach